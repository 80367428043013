/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { API } from "../../services/api-services";

const ChatWidget = () => {
  const history = useHistory();
  const [profileData, setProfileData] = useState({});

  useEffect(() => {
    API.post("/getProfileInfo")
      .then((res) => {
        setProfileData(res?.data?.allStudentDetails);
      })
      .catch((e) => {
        console.error("Error while fetching profile info", e);
      });
  }, []);

  useEffect(() => {
    let iframeDiv = document.getElementById("lsq-chatbot");

    if (iframeDiv) {
      // Set iframe visibility to visible since it's hidden by default in index.html
      iframeDiv.style.visibility = "visible";

      // trigger the chat bot directly without showing the message icon
      if ("TRIGGER_CHATBOT" in window) {
        window.TRIGGER_CHATBOT();
      }

      // Callback function to handle class changes observed on the iframe
      const handleClassChange = (mutationsList) => {
        mutationsList.forEach((mutation) => {
          if (
            mutation.type === "attributes" &&
            mutation.attributeName === "class"
          ) {
            // Check if chat screen is opened by detecting the presence of the class
            const chatOpen = document.getElementsByClassName("chatbot-opened");

            // If the chat screen is minimized, navigate to the dashboard and hide the chat bot
            if (chatOpen.length === 0) {
              history.push("/dashboard");
              iframeDiv.style.visibility = "hidden";
            }
          }
        });
      };

      const observer = new MutationObserver(handleClassChange);
      observer.observe(iframeDiv, { attributes: true });

      return () => {
        // disconnect observer after unmounting the component
        observer.disconnect();
      };
    }
  }, []);

  useEffect(() => {
    if (profileData && Object.keys(profileData).length > 0) {
      const details = {
        loggedInUserInfo: {
          name: profileData.studentName || "",
          email: profileData.studentEmail || "",
          phoneNumber: profileData.parentContact || "",
          leadUniqueIdentifier: profileData.parentContact || "",
        },
        visitorInfo: {},
      };

      if ("CONVERSE" in window) {
        window.CONVERSE(details);
      }
    }
  }, [profileData]);

  return null;
};

export default ChatWidget;
